@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	font-family: Arial, sans-serif;
	--scroll-behavior: smooth !important;
	scroll-behavior: smooth !important;
	position: relative;
}

body {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;

	/* Define a cor de fundo da barra de rolagem e do thumb */
	scrollbar-color: #34904e #e4e4e4; /* thumb and track colors */
	scrollbar-width: thin; /* pode ser 'auto', 'thin', ou 'none' */
}

input,
textarea,
select {
  font-size: 16px;
}

/* Para Webkit (Chrome, Edge, Safari) */
*::-webkit-scrollbar {
	width: 14px; /* largura da barra de rolagem */
}

*::selection {
	color: #e4e4e4;
	background: #34904e;
}

.slick-slider .slick-slide {
	padding: 0 8px;
}

.slider-container {
	overflow: visible; /* Permite que o próximo card seja visível */
}

.slick-dots li.slick-active button:before {
	color: #285e31 !important;
}

.slick-slide {
	padding-right: 10px; /* Espaçamento entre os slides */
}

@media (max-width: 480px) {
	.slick-slide {
		padding-right: 5px; /* Ajuste para telas menores */
	}
}

/* Esconder scrollbar para navegadores baseados em WebKit (Chrome, Safari, Opera) */
.scrollbar-hidden::-webkit-scrollbar {
	display: none;
}

/* Esconder scrollbar para Firefox */
.scrollbar-hidden {
	scrollbar-width: none; /* Firefox */
}

/* Esconder scrollbar para Internet Explorer e Edge */
.scrollbar-hidden {
	-ms-overflow-style: none; /* IE e Edge */
}

@layer utilities {
	.line-clamp-2 {
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.masonry {
		column-gap: 1.5em;
		column-count: 1;
		max-width: 80rem;
	}
	.masonry-sm {
		column-gap: 1.5em;
		column-count: 2;
	}
	.masonry-md {
		column-gap: 1.5em;
		column-count: 3;
	}
	.break-inside {
		break-inside: avoid;
	}

	/* Adicionando estilos para os itens de patologia e círculos */
	.patology-item {
		@apply flex items-center justify-between py-4;
	}
	.no-scroll {
		@apply overflow-hidden;
	}
	.circle {
		@apply flex h-5 w-5 items-center justify-center rounded-full border-2 border-gray-400;
	}
	.circle-inner {
		@apply h-3 w-3 rounded-full ;
	}
	.selected .circle {
		@apply border-brand;
	}
	.selected .circle-inner {
		@apply bg-brand;
	}
	.selected .patology-name {
		@apply text-brand;
	}
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Poppins', sans-serif;
  scroll-margin-top: 6rem;
}

p {
  font-family: 'Lexend', sans-serif;
}

/* Estilo da galeria estilo Pinterest */
.pinterest-grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 16px;
}

.pinterest-grid img {
	width: 100%;
	height: auto;
	object-fit: cover;
	border-radius: 10px;
}

/* Ajusta para 2 colunas em telas médias */
@media (max-width: 1024px) {
	.pinterest-grid {
		grid-template-columns: repeat(2, 1fr);
	}
}

/* Ajusta para 1 coluna em telas pequenas */
@media (max-width: 768px) {
	.pinterest-grid {
		grid-template-columns: 1fr;
	}
}

blockquote > p  {
 margin-bottom: 0px !important;
}

li > strong {
color: #285E31 !important;
}

a strong {
  color: inherit !important;
}

.video-container {
  width: 100%;
  max-width: 900px; 
  margin: auto;
  aspect-ratio: 16 / 9;
  display: flex;
}

.video-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.no-select {
  user-select: none; /* Para navegadores modernos */
  -webkit-user-select: none; /* Para Safari e Chrome */
  -moz-user-select: none; /* Para Firefox */
  -ms-user-select: none; /* Para IE/Edge */
}